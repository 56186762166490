.sidebar {
    width: 170px;
    height: 601px;
    pointer-events: all;
    position: absolute;
    right: 20px;
    top: 20%;
    z-index: 5; 

    transform: translateX(100%);
    transition: all .4s $ani-easeInBack;
    
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
        padding: 50px 10px 60px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &--pwth {

            $space: 6px;

            .ios {
                display: block;
                margin-bottom: $space;
                @include sprite($store_appstore_side);

                &:hover {
                    @include sprite($store_appstore_side-hov);
                }
            }
            .android {
                margin-bottom: $space;
                display: block;
                @include sprite($store_googleplay_side);

                &:hover {
                    @include sprite($store_googleplay_side-hov);
                }
            }
            .apk {
                margin-bottom: $space;
                display: block;
                @include sprite($store_pc_side);

                &:hover {
                    @include sprite($store_pc_side-hov);
                }
            }

 
            .appicon {
                margin-bottom: $space;
                display: block;
                width: 130px;
                height: 130px;
                background: url(../../dist/assets/images/common/appicon-side.png);
                background-size: contain;

            }

            .normal {
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: $space;
                display: block;
                font-size: 24px;
                text-align: center; 
                color: $white;
                font-weight: 600;
                text-decoration: none;
                height: 34px;


                &:hover {
                    background: #324385;
                }

            
            }

            .topup {
                display: block;
                @include sprite($topup-side);

                &:hover {
                    filter: brightness(110%);
                }
            }

            // .topup {
            //     display: block;
            //     @include sprite($prereg-side);

            //     &:hover {
            //         filter: brightness(110%);
            //     }
            // }

            .split {
                display: block;
                margin-bottom: $space;
                @include sprite($split-side);
            }

            .hamburger {
                background-color: #1e31a2;
                padding: 16px 14px;

            }




    }

    &__toggle {
        background-color: transparent;
        border: none;
        @include sprite($side-open);
        position: absolute;
        font-size: 0px;
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% - 2px);

        &:hover {
            cursor: pointer;
            filter: brightness(120%);
        }

        &:focus {
            outline: none;
        }
    }

    &.active {
        transform: translateX(0%);
        .sidebar__toggle {
            @include sprite($side-close);
        }
    }

}