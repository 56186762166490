// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$all-name: 'all';
$all-x: 1209px;
$all-y: 333px;
$all-offset-x: -1209px;
$all-offset-y: -333px;
$all-width: 30px;
$all-height: 30px;
$all-total-width: 1243px;
$all-total-height: 1022px;
$all-image: '../images/_sprites-full.png';
$all: (1209px, 333px, -1209px, -333px, 30px, 30px, 1243px, 1022px, '../images/_sprites-full.png', 'all', );
$char-bar-bg-name: 'char-bar-bg';
$char-bar-bg-x: 750px;
$char-bar-bg-y: 0px;
$char-bar-bg-offset-x: -750px;
$char-bar-bg-offset-y: 0px;
$char-bar-bg-width: 170px;
$char-bar-bg-height: 440px;
$char-bar-bg-total-width: 1243px;
$char-bar-bg-total-height: 1022px;
$char-bar-bg-image: '../images/_sprites-full.png';
$char-bar-bg: (750px, 0px, -750px, 0px, 170px, 440px, 1243px, 1022px, '../images/_sprites-full.png', 'char-bar-bg', );
$char-left-hov-name: 'char-left-hov';
$char-left-hov-x: 760px;
$char-left-hov-y: 588px;
$char-left-hov-offset-x: -760px;
$char-left-hov-offset-y: -588px;
$char-left-hov-width: 149px;
$char-left-hov-height: 84px;
$char-left-hov-total-width: 1243px;
$char-left-hov-total-height: 1022px;
$char-left-hov-image: '../images/_sprites-full.png';
$char-left-hov: (760px, 588px, -760px, -588px, 149px, 84px, 1243px, 1022px, '../images/_sprites-full.png', 'char-left-hov', );
$char-left-name: 'char-left';
$char-left-x: 0px;
$char-left-y: 938px;
$char-left-offset-x: 0px;
$char-left-offset-y: -938px;
$char-left-width: 149px;
$char-left-height: 84px;
$char-left-total-width: 1243px;
$char-left-total-height: 1022px;
$char-left-image: '../images/_sprites-full.png';
$char-left: (0px, 938px, 0px, -938px, 149px, 84px, 1243px, 1022px, '../images/_sprites-full.png', 'char-left', );
$code-hov-name: 'code-hov';
$code-hov-x: 0px;
$code-hov-y: 588px;
$code-hov-offset-x: 0px;
$code-hov-offset-y: -588px;
$code-hov-width: 380px;
$code-hov-height: 120px;
$code-hov-total-width: 1243px;
$code-hov-total-height: 1022px;
$code-hov-image: '../images/_sprites-full.png';
$code-hov: (0px, 588px, 0px, -588px, 380px, 120px, 1243px, 1022px, '../images/_sprites-full.png', 'code-hov', );
$code-name: 'code';
$code-x: 380px;
$code-y: 588px;
$code-offset-x: -380px;
$code-offset-y: -588px;
$code-width: 380px;
$code-height: 120px;
$code-total-width: 1243px;
$code-total-height: 1022px;
$code-image: '../images/_sprites-full.png';
$code: (380px, 588px, -380px, -588px, 380px, 120px, 1243px, 1022px, '../images/_sprites-full.png', 'code', );
$decor-corner-name: 'decor-corner';
$decor-corner-x: 1105px;
$decor-corner-y: 284px;
$decor-corner-offset-x: -1105px;
$decor-corner-offset-y: -284px;
$decor-corner-width: 104px;
$decor-corner-height: 105px;
$decor-corner-total-width: 1243px;
$decor-corner-total-height: 1022px;
$decor-corner-image: '../images/_sprites-full.png';
$decor-corner: (1105px, 284px, -1105px, -284px, 104px, 105px, 1243px, 1022px, '../images/_sprites-full.png', 'decor-corner', );
$download-mb-name: 'download-mb';
$download-mb-x: 376px;
$download-mb-y: 304px;
$download-mb-offset-x: -376px;
$download-mb-offset-y: -304px;
$download-mb-width: 338px;
$download-mb-height: 90px;
$download-mb-total-width: 1243px;
$download-mb-total-height: 1022px;
$download-mb-image: '../images/_sprites-full.png';
$download-mb: (376px, 304px, -376px, -304px, 338px, 90px, 1243px, 1022px, '../images/_sprites-full.png', 'download-mb', );
$download-top-name: 'download-top';
$download-top-x: 684px;
$download-top-y: 708px;
$download-top-offset-x: -684px;
$download-top-offset-y: -708px;
$download-top-width: 200px;
$download-top-height: 59px;
$download-top-total-width: 1243px;
$download-top-total-height: 1022px;
$download-top-image: '../images/_sprites-full.png';
$download-top: (684px, 708px, -684px, -708px, 200px, 59px, 1243px, 1022px, '../images/_sprites-full.png', 'download-top', );
$dropdown-name: 'dropdown';
$dropdown-x: 1184px;
$dropdown-y: 799px;
$dropdown-offset-x: -1184px;
$dropdown-offset-y: -799px;
$dropdown-width: 35px;
$dropdown-height: 21px;
$dropdown-total-width: 1243px;
$dropdown-total-height: 1022px;
$dropdown-image: '../images/_sprites-full.png';
$dropdown: (1184px, 799px, -1184px, -799px, 35px, 21px, 1243px, 1022px, '../images/_sprites-full.png', 'dropdown', );
$events-page-active-name: 'events_page-active';
$events-page-active-x: 1215px;
$events-page-active-y: 769px;
$events-page-active-offset-x: -1215px;
$events-page-active-offset-y: -769px;
$events-page-active-width: 28px;
$events-page-active-height: 28px;
$events-page-active-total-width: 1243px;
$events-page-active-total-height: 1022px;
$events-page-active-image: '../images/_sprites-full.png';
$events-page-active: (1215px, 769px, -1215px, -769px, 28px, 28px, 1243px, 1022px, '../images/_sprites-full.png', 'events_page-active', );
$events-page-name: 'events_page';
$events-page-x: 1155px;
$events-page-y: 799px;
$events-page-offset-x: -1155px;
$events-page-offset-y: -799px;
$events-page-width: 29px;
$events-page-height: 28px;
$events-page-total-width: 1243px;
$events-page-total-height: 1022px;
$events-page-image: '../images/_sprites-full.png';
$events-page: (1155px, 799px, -1155px, -799px, 29px, 28px, 1243px, 1022px, '../images/_sprites-full.png', 'events_page', );
$facebook-top-name: 'facebook-top';
$facebook-top-x: 1176px;
$facebook-top-y: 629px;
$facebook-top-offset-x: -1176px;
$facebook-top-offset-y: -629px;
$facebook-top-width: 57px;
$facebook-top-height: 58px;
$facebook-top-total-width: 1243px;
$facebook-top-total-height: 1022px;
$facebook-top-image: '../images/_sprites-full.png';
$facebook-top: (1176px, 629px, -1176px, -629px, 57px, 58px, 1243px, 1022px, '../images/_sprites-full.png', 'facebook-top', );
$features-benefit-hov-name: 'features-benefit-hov';
$features-benefit-hov-x: 0px;
$features-benefit-hov-y: 778px;
$features-benefit-hov-offset-x: 0px;
$features-benefit-hov-offset-y: -778px;
$features-benefit-hov-width: 185px;
$features-benefit-hov-height: 80px;
$features-benefit-hov-total-width: 1243px;
$features-benefit-hov-total-height: 1022px;
$features-benefit-hov-image: '../images/_sprites-full.png';
$features-benefit-hov: (0px, 778px, 0px, -778px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-benefit-hov', );
$features-benefit-name: 'features-benefit';
$features-benefit-x: 185px;
$features-benefit-y: 778px;
$features-benefit-offset-x: -185px;
$features-benefit-offset-y: -778px;
$features-benefit-width: 185px;
$features-benefit-height: 80px;
$features-benefit-total-width: 1243px;
$features-benefit-total-height: 1022px;
$features-benefit-image: '../images/_sprites-full.png';
$features-benefit: (185px, 778px, -185px, -778px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-benefit', );
$features-character-hov-name: 'features-character-hov';
$features-character-hov-x: 370px;
$features-character-hov-y: 778px;
$features-character-hov-offset-x: -370px;
$features-character-hov-offset-y: -778px;
$features-character-hov-width: 185px;
$features-character-hov-height: 80px;
$features-character-hov-total-width: 1243px;
$features-character-hov-total-height: 1022px;
$features-character-hov-image: '../images/_sprites-full.png';
$features-character-hov: (370px, 778px, -370px, -778px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-character-hov', );
$features-character-name: 'features-character';
$features-character-x: 555px;
$features-character-y: 778px;
$features-character-offset-x: -555px;
$features-character-offset-y: -778px;
$features-character-width: 185px;
$features-character-height: 80px;
$features-character-total-width: 1243px;
$features-character-total-height: 1022px;
$features-character-image: '../images/_sprites-full.png';
$features-character: (555px, 778px, -555px, -778px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-character', );
$features-clan-hov-name: 'features-clan-hov';
$features-clan-hov-x: 920px;
$features-clan-hov-y: 0px;
$features-clan-hov-offset-x: -920px;
$features-clan-hov-offset-y: 0px;
$features-clan-hov-width: 185px;
$features-clan-hov-height: 80px;
$features-clan-hov-total-width: 1243px;
$features-clan-hov-total-height: 1022px;
$features-clan-hov-image: '../images/_sprites-full.png';
$features-clan-hov: (920px, 0px, -920px, 0px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-clan-hov', );
$features-clan-name: 'features-clan';
$features-clan-x: 920px;
$features-clan-y: 80px;
$features-clan-offset-x: -920px;
$features-clan-offset-y: -80px;
$features-clan-width: 185px;
$features-clan-height: 80px;
$features-clan-total-width: 1243px;
$features-clan-total-height: 1022px;
$features-clan-image: '../images/_sprites-full.png';
$features-clan: (920px, 80px, -920px, -80px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-clan', );
$features-pk-hov-name: 'features-pk-hov';
$features-pk-hov-x: 920px;
$features-pk-hov-y: 160px;
$features-pk-hov-offset-x: -920px;
$features-pk-hov-offset-y: -160px;
$features-pk-hov-width: 185px;
$features-pk-hov-height: 80px;
$features-pk-hov-total-width: 1243px;
$features-pk-hov-total-height: 1022px;
$features-pk-hov-image: '../images/_sprites-full.png';
$features-pk-hov: (920px, 160px, -920px, -160px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-pk-hov', );
$features-pk-name: 'features-pk';
$features-pk-x: 920px;
$features-pk-y: 240px;
$features-pk-offset-x: -920px;
$features-pk-offset-y: -240px;
$features-pk-width: 185px;
$features-pk-height: 80px;
$features-pk-total-width: 1243px;
$features-pk-total-height: 1022px;
$features-pk-image: '../images/_sprites-full.png';
$features-pk: (920px, 240px, -920px, -240px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'features-pk', );
$gallery-page-hov-name: 'gallery-page-hov';
$gallery-page-hov-x: 1223px;
$gallery-page-hov-y: 144px;
$gallery-page-hov-offset-x: -1223px;
$gallery-page-hov-offset-y: -144px;
$gallery-page-hov-width: 20px;
$gallery-page-hov-height: 20px;
$gallery-page-hov-total-width: 1243px;
$gallery-page-hov-total-height: 1022px;
$gallery-page-hov-image: '../images/_sprites-full.png';
$gallery-page-hov: (1223px, 144px, -1223px, -144px, 20px, 20px, 1243px, 1022px, '../images/_sprites-full.png', 'gallery-page-hov', );
$gallery-page-name: 'gallery-page';
$gallery-page-x: 1223px;
$gallery-page-y: 164px;
$gallery-page-offset-x: -1223px;
$gallery-page-offset-y: -164px;
$gallery-page-width: 20px;
$gallery-page-height: 20px;
$gallery-page-total-width: 1243px;
$gallery-page-total-height: 1022px;
$gallery-page-image: '../images/_sprites-full.png';
$gallery-page: (1223px, 164px, -1223px, -164px, 20px, 20px, 1243px, 1022px, '../images/_sprites-full.png', 'gallery-page', );
$gallery-tab-bg-hov-name: 'gallery-tab-bg-hov';
$gallery-tab-bg-hov-x: 920px;
$gallery-tab-bg-hov-y: 800px;
$gallery-tab-bg-hov-offset-x: -920px;
$gallery-tab-bg-hov-offset-y: -800px;
$gallery-tab-bg-hov-width: 174px;
$gallery-tab-bg-hov-height: 49px;
$gallery-tab-bg-hov-total-width: 1243px;
$gallery-tab-bg-hov-total-height: 1022px;
$gallery-tab-bg-hov-image: '../images/_sprites-full.png';
$gallery-tab-bg-hov: (920px, 800px, -920px, -800px, 174px, 49px, 1243px, 1022px, '../images/_sprites-full.png', 'gallery-tab-bg-hov', );
$gallery-tab-bg-name: 'gallery-tab-bg';
$gallery-tab-bg-x: 740px;
$gallery-tab-bg-y: 778px;
$gallery-tab-bg-offset-x: -740px;
$gallery-tab-bg-offset-y: -778px;
$gallery-tab-bg-width: 174px;
$gallery-tab-bg-height: 49px;
$gallery-tab-bg-total-width: 1243px;
$gallery-tab-bg-total-height: 1022px;
$gallery-tab-bg-image: '../images/_sprites-full.png';
$gallery-tab-bg: (740px, 778px, -740px, -778px, 174px, 49px, 1243px, 1022px, '../images/_sprites-full.png', 'gallery-tab-bg', );
$gallery-tab-line-name: 'gallery-tab-line';
$gallery-tab-line-x: 370px;
$gallery-tab-line-y: 907px;
$gallery-tab-line-offset-x: -370px;
$gallery-tab-line-offset-y: -907px;
$gallery-tab-line-width: 691px;
$gallery-tab-line-height: 5px;
$gallery-tab-line-total-width: 1243px;
$gallery-tab-line-total-height: 1022px;
$gallery-tab-line-image: '../images/_sprites-full.png';
$gallery-tab-line: (370px, 907px, -370px, -907px, 691px, 5px, 1243px, 1022px, '../images/_sprites-full.png', 'gallery-tab-line', );
$logo-pw-name: 'logo-pw';
$logo-pw-x: 1105px;
$logo-pw-y: 769px;
$logo-pw-offset-x: -1105px;
$logo-pw-offset-y: -769px;
$logo-pw-width: 110px;
$logo-pw-height: 30px;
$logo-pw-total-width: 1243px;
$logo-pw-total-height: 1022px;
$logo-pw-image: '../images/_sprites-full.png';
$logo-pw: (1105px, 769px, -1105px, -769px, 110px, 30px, 1243px, 1022px, '../images/_sprites-full.png', 'logo-pw', );
$logo-vng-name: 'logo-vng';
$logo-vng-x: 1209px;
$logo-vng-y: 284px;
$logo-vng-offset-x: -1209px;
$logo-vng-offset-y: -284px;
$logo-vng-width: 33px;
$logo-vng-height: 49px;
$logo-vng-total-width: 1243px;
$logo-vng-total-height: 1022px;
$logo-vng-image: '../images/_sprites-full.png';
$logo-vng: (1209px, 284px, -1209px, -284px, 33px, 49px, 1243px, 1022px, '../images/_sprites-full.png', 'logo-vng', );
$music-mute-name: 'music-mute';
$music-mute-x: 1176px;
$music-mute-y: 699px;
$music-mute-offset-x: -1176px;
$music-mute-offset-y: -699px;
$music-mute-width: 50px;
$music-mute-height: 50px;
$music-mute-total-width: 1243px;
$music-mute-total-height: 1022px;
$music-mute-image: '../images/_sprites-full.png';
$music-mute: (1176px, 699px, -1176px, -699px, 50px, 50px, 1243px, 1022px, '../images/_sprites-full.png', 'music-mute', );
$music-name: 'music';
$music-x: 1105px;
$music-y: 799px;
$music-offset-x: -1105px;
$music-offset-y: -799px;
$music-width: 50px;
$music-height: 50px;
$music-total-width: 1243px;
$music-total-height: 1022px;
$music-image: '../images/_sprites-full.png';
$music: (1105px, 799px, -1105px, -799px, 50px, 50px, 1243px, 1022px, '../images/_sprites-full.png', 'music', );
$nav-split-name: 'nav-split';
$nav-split-x: 1223px;
$nav-split-y: 198px;
$nav-split-offset-x: -1223px;
$nav-split-offset-y: -198px;
$nav-split-width: 12px;
$nav-split-height: 12px;
$nav-split-total-width: 1243px;
$nav-split-total-height: 1022px;
$nav-split-image: '../images/_sprites-full.png';
$nav-split: (1223px, 198px, -1223px, -198px, 12px, 12px, 1243px, 1022px, '../images/_sprites-full.png', 'nav-split', );
$page-next-name: 'page-next';
$page-next-x: 1223px;
$page-next-y: 90px;
$page-next-offset-x: -1223px;
$page-next-offset-y: -90px;
$page-next-width: 16px;
$page-next-height: 27px;
$page-next-total-width: 1243px;
$page-next-total-height: 1022px;
$page-next-image: '../images/_sprites-full.png';
$page-next: (1223px, 90px, -1223px, -90px, 16px, 27px, 1243px, 1022px, '../images/_sprites-full.png', 'page-next', );
$page-prev-name: 'page-prev';
$page-prev-x: 1223px;
$page-prev-y: 117px;
$page-prev-offset-x: -1223px;
$page-prev-offset-y: -117px;
$page-prev-width: 16px;
$page-prev-height: 27px;
$page-prev-total-width: 1243px;
$page-prev-total-height: 1022px;
$page-prev-image: '../images/_sprites-full.png';
$page-prev: (1223px, 117px, -1223px, -117px, 16px, 27px, 1243px, 1022px, '../images/_sprites-full.png', 'page-prev', );
$prereg-mb-name: 'prereg-mb';
$prereg-mb-x: 376px;
$prereg-mb-y: 394px;
$prereg-mb-offset-x: -376px;
$prereg-mb-offset-y: -394px;
$prereg-mb-width: 338px;
$prereg-mb-height: 90px;
$prereg-mb-total-width: 1243px;
$prereg-mb-total-height: 1022px;
$prereg-mb-image: '../images/_sprites-full.png';
$prereg-mb: (376px, 394px, -376px, -394px, 338px, 90px, 1243px, 1022px, '../images/_sprites-full.png', 'prereg-mb', );
$rank-next-name: 'rank-next';
$rank-next-x: 1105px;
$rank-next-y: 629px;
$rank-next-offset-x: -1105px;
$rank-next-offset-y: -629px;
$rank-next-width: 71px;
$rank-next-height: 70px;
$rank-next-total-width: 1243px;
$rank-next-total-height: 1022px;
$rank-next-image: '../images/_sprites-full.png';
$rank-next: (1105px, 629px, -1105px, -629px, 71px, 70px, 1243px, 1022px, '../images/_sprites-full.png', 'rank-next', );
$rank-prev-name: 'rank-prev';
$rank-prev-x: 1105px;
$rank-prev-y: 699px;
$rank-prev-offset-x: -1105px;
$rank-prev-offset-y: -699px;
$rank-prev-width: 71px;
$rank-prev-height: 70px;
$rank-prev-total-width: 1243px;
$rank-prev-total-height: 1022px;
$rank-prev-image: '../images/_sprites-full.png';
$rank-prev: (1105px, 699px, -1105px, -699px, 71px, 70px, 1243px, 1022px, '../images/_sprites-full.png', 'rank-prev', );
$rank-tab-bg-act-name: 'rank-tab-bg-act';
$rank-tab-bg-act-x: 370px;
$rank-tab-bg-act-y: 858px;
$rank-tab-bg-act-offset-x: -370px;
$rank-tab-bg-act-offset-y: -858px;
$rank-tab-bg-act-width: 174px;
$rank-tab-bg-act-height: 49px;
$rank-tab-bg-act-total-width: 1243px;
$rank-tab-bg-act-total-height: 1022px;
$rank-tab-bg-act-image: '../images/_sprites-full.png';
$rank-tab-bg-act: (370px, 858px, -370px, -858px, 174px, 49px, 1243px, 1022px, '../images/_sprites-full.png', 'rank-tab-bg-act', );
$rank-tab-bg-hov-name: 'rank-tab-bg-hov';
$rank-tab-bg-hov-x: 0px;
$rank-tab-bg-hov-y: 708px;
$rank-tab-bg-hov-offset-x: 0px;
$rank-tab-bg-hov-offset-y: -708px;
$rank-tab-bg-hov-width: 342px;
$rank-tab-bg-hov-height: 70px;
$rank-tab-bg-hov-total-width: 1243px;
$rank-tab-bg-hov-total-height: 1022px;
$rank-tab-bg-hov-image: '../images/_sprites-full.png';
$rank-tab-bg-hov: (0px, 708px, 0px, -708px, 342px, 70px, 1243px, 1022px, '../images/_sprites-full.png', 'rank-tab-bg-hov', );
$rank-tab-bg-name: 'rank-tab-bg';
$rank-tab-bg-x: 342px;
$rank-tab-bg-y: 708px;
$rank-tab-bg-offset-x: -342px;
$rank-tab-bg-offset-y: -708px;
$rank-tab-bg-width: 342px;
$rank-tab-bg-height: 70px;
$rank-tab-bg-total-width: 1243px;
$rank-tab-bg-total-height: 1022px;
$rank-tab-bg-image: '../images/_sprites-full.png';
$rank-tab-bg: (342px, 708px, -342px, -708px, 342px, 70px, 1243px, 1022px, '../images/_sprites-full.png', 'rank-tab-bg', );
$side-close-name: 'side-close';
$side-close-x: 1105px;
$side-close-y: 90px;
$side-close-offset-x: -1105px;
$side-close-offset-y: -90px;
$side-close-width: 59px;
$side-close-height: 194px;
$side-close-total-width: 1243px;
$side-close-total-height: 1022px;
$side-close-image: '../images/_sprites-full.png';
$side-close: (1105px, 90px, -1105px, -90px, 59px, 194px, 1243px, 1022px, '../images/_sprites-full.png', 'side-close', );
$side-open-name: 'side-open';
$side-open-x: 1164px;
$side-open-y: 90px;
$side-open-offset-x: -1164px;
$side-open-offset-y: -90px;
$side-open-width: 59px;
$side-open-height: 194px;
$side-open-total-width: 1243px;
$side-open-total-height: 1022px;
$side-open-image: '../images/_sprites-full.png';
$side-open: (1164px, 90px, -1164px, -90px, 59px, 194px, 1243px, 1022px, '../images/_sprites-full.png', 'side-open', );
$split-side-name: 'split-side';
$split-side-x: 1105px;
$split-side-y: 849px;
$split-side-offset-x: -1105px;
$split-side-offset-y: -849px;
$split-side-width: 90px;
$split-side-height: 1px;
$split-side-total-width: 1243px;
$split-side-total-height: 1022px;
$split-side-image: '../images/_sprites-full.png';
$split-side: (1105px, 849px, -1105px, -849px, 90px, 1px, 1243px, 1022px, '../images/_sprites-full.png', 'split-side', );
$star-name: 'star';
$star-x: 1223px;
$star-y: 184px;
$star-offset-x: -1223px;
$star-offset-y: -184px;
$star-width: 15px;
$star-height: 14px;
$star-total-width: 1243px;
$star-total-height: 1022px;
$star-image: '../images/_sprites-full.png';
$star: (1223px, 184px, -1223px, -184px, 15px, 14px, 1243px, 1022px, '../images/_sprites-full.png', 'star', );
$store-appstore-side-hov-name: 'store_appstore_side-hov';
$store-appstore-side-hov-x: 1105px;
$store-appstore-side-hov-y: 389px;
$store-appstore-side-hov-offset-x: -1105px;
$store-appstore-side-hov-offset-y: -389px;
$store-appstore-side-hov-width: 130px;
$store-appstore-side-hov-height: 40px;
$store-appstore-side-hov-total-width: 1243px;
$store-appstore-side-hov-total-height: 1022px;
$store-appstore-side-hov-image: '../images/_sprites-full.png';
$store-appstore-side-hov: (1105px, 389px, -1105px, -389px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_appstore_side-hov', );
$store-appstore-side-name: 'store_appstore_side';
$store-appstore-side-x: 1105px;
$store-appstore-side-y: 429px;
$store-appstore-side-offset-x: -1105px;
$store-appstore-side-offset-y: -429px;
$store-appstore-side-width: 130px;
$store-appstore-side-height: 40px;
$store-appstore-side-total-width: 1243px;
$store-appstore-side-total-height: 1022px;
$store-appstore-side-image: '../images/_sprites-full.png';
$store-appstore-side: (1105px, 429px, -1105px, -429px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_appstore_side', );
$store-appstore-hov-name: 'store_appstore-hov';
$store-appstore-hov-x: 920px;
$store-appstore-hov-y: 320px;
$store-appstore-hov-offset-x: -920px;
$store-appstore-hov-offset-y: -320px;
$store-appstore-hov-width: 185px;
$store-appstore-hov-height: 80px;
$store-appstore-hov-total-width: 1243px;
$store-appstore-hov-total-height: 1022px;
$store-appstore-hov-image: '../images/_sprites-full.png';
$store-appstore-hov: (920px, 320px, -920px, -320px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_appstore-hov', );
$store-appstore-name: 'store_appstore';
$store-appstore-x: 920px;
$store-appstore-y: 400px;
$store-appstore-offset-x: -920px;
$store-appstore-offset-y: -400px;
$store-appstore-width: 185px;
$store-appstore-height: 80px;
$store-appstore-total-width: 1243px;
$store-appstore-total-height: 1022px;
$store-appstore-image: '../images/_sprites-full.png';
$store-appstore: (920px, 400px, -920px, -400px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_appstore', );
$store-googleplay-side-hov-name: 'store_googleplay_side-hov';
$store-googleplay-side-hov-x: 1105px;
$store-googleplay-side-hov-y: 469px;
$store-googleplay-side-hov-offset-x: -1105px;
$store-googleplay-side-hov-offset-y: -469px;
$store-googleplay-side-hov-width: 130px;
$store-googleplay-side-hov-height: 40px;
$store-googleplay-side-hov-total-width: 1243px;
$store-googleplay-side-hov-total-height: 1022px;
$store-googleplay-side-hov-image: '../images/_sprites-full.png';
$store-googleplay-side-hov: (1105px, 469px, -1105px, -469px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_googleplay_side-hov', );
$store-googleplay-side-name: 'store_googleplay_side';
$store-googleplay-side-x: 1105px;
$store-googleplay-side-y: 509px;
$store-googleplay-side-offset-x: -1105px;
$store-googleplay-side-offset-y: -509px;
$store-googleplay-side-width: 130px;
$store-googleplay-side-height: 40px;
$store-googleplay-side-total-width: 1243px;
$store-googleplay-side-total-height: 1022px;
$store-googleplay-side-image: '../images/_sprites-full.png';
$store-googleplay-side: (1105px, 509px, -1105px, -509px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_googleplay_side', );
$store-googleplay-hov-name: 'store_googleplay-hov';
$store-googleplay-hov-x: 920px;
$store-googleplay-hov-y: 480px;
$store-googleplay-hov-offset-x: -920px;
$store-googleplay-hov-offset-y: -480px;
$store-googleplay-hov-width: 185px;
$store-googleplay-hov-height: 80px;
$store-googleplay-hov-total-width: 1243px;
$store-googleplay-hov-total-height: 1022px;
$store-googleplay-hov-image: '../images/_sprites-full.png';
$store-googleplay-hov: (920px, 480px, -920px, -480px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_googleplay-hov', );
$store-googleplay-name: 'store_googleplay';
$store-googleplay-x: 920px;
$store-googleplay-y: 560px;
$store-googleplay-offset-x: -920px;
$store-googleplay-offset-y: -560px;
$store-googleplay-width: 185px;
$store-googleplay-height: 80px;
$store-googleplay-total-width: 1243px;
$store-googleplay-total-height: 1022px;
$store-googleplay-image: '../images/_sprites-full.png';
$store-googleplay: (920px, 560px, -920px, -560px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_googleplay', );
$store-nox-hov-name: 'store_nox-hov';
$store-nox-hov-x: 920px;
$store-nox-hov-y: 640px;
$store-nox-hov-offset-x: -920px;
$store-nox-hov-offset-y: -640px;
$store-nox-hov-width: 185px;
$store-nox-hov-height: 80px;
$store-nox-hov-total-width: 1243px;
$store-nox-hov-total-height: 1022px;
$store-nox-hov-image: '../images/_sprites-full.png';
$store-nox-hov: (920px, 640px, -920px, -640px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_nox-hov', );
$store-nox-name: 'store_nox';
$store-nox-x: 920px;
$store-nox-y: 720px;
$store-nox-offset-x: -920px;
$store-nox-offset-y: -720px;
$store-nox-width: 185px;
$store-nox-height: 80px;
$store-nox-total-width: 1243px;
$store-nox-total-height: 1022px;
$store-nox-image: '../images/_sprites-full.png';
$store-nox: (920px, 720px, -920px, -720px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_nox', );
$store-pc-side-hov-name: 'store_pc_side-hov';
$store-pc-side-hov-x: 1105px;
$store-pc-side-hov-y: 549px;
$store-pc-side-hov-offset-x: -1105px;
$store-pc-side-hov-offset-y: -549px;
$store-pc-side-hov-width: 130px;
$store-pc-side-hov-height: 40px;
$store-pc-side-hov-total-width: 1243px;
$store-pc-side-hov-total-height: 1022px;
$store-pc-side-hov-image: '../images/_sprites-full.png';
$store-pc-side-hov: (1105px, 549px, -1105px, -549px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_pc_side-hov', );
$store-pc-side-name: 'store_pc_side';
$store-pc-side-x: 1105px;
$store-pc-side-y: 589px;
$store-pc-side-offset-x: -1105px;
$store-pc-side-offset-y: -589px;
$store-pc-side-width: 130px;
$store-pc-side-height: 40px;
$store-pc-side-total-width: 1243px;
$store-pc-side-total-height: 1022px;
$store-pc-side-image: '../images/_sprites-full.png';
$store-pc-side: (1105px, 589px, -1105px, -589px, 130px, 40px, 1243px, 1022px, '../images/_sprites-full.png', 'store_pc_side', );
$store-pc-hov-name: 'store_pc-hov';
$store-pc-hov-x: 0px;
$store-pc-hov-y: 858px;
$store-pc-hov-offset-x: 0px;
$store-pc-hov-offset-y: -858px;
$store-pc-hov-width: 185px;
$store-pc-hov-height: 80px;
$store-pc-hov-total-width: 1243px;
$store-pc-hov-total-height: 1022px;
$store-pc-hov-image: '../images/_sprites-full.png';
$store-pc-hov: (0px, 858px, 0px, -858px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_pc-hov', );
$store-pc-name: 'store_pc';
$store-pc-x: 185px;
$store-pc-y: 858px;
$store-pc-offset-x: -185px;
$store-pc-offset-y: -858px;
$store-pc-width: 185px;
$store-pc-height: 80px;
$store-pc-total-width: 1243px;
$store-pc-total-height: 1022px;
$store-pc-image: '../images/_sprites-full.png';
$store-pc: (185px, 858px, -185px, -858px, 185px, 80px, 1243px, 1022px, '../images/_sprites-full.png', 'store_pc', );
$title-bg-mb-name: 'title-bg-mb';
$title-bg-mb-x: 0px;
$title-bg-mb-y: 0px;
$title-bg-mb-offset-x: 0px;
$title-bg-mb-offset-y: 0px;
$title-bg-mb-width: 750px;
$title-bg-mb-height: 152px;
$title-bg-mb-total-width: 1243px;
$title-bg-mb-total-height: 1022px;
$title-bg-mb-image: '../images/_sprites-full.png';
$title-bg-mb: (0px, 0px, 0px, 0px, 750px, 152px, 1243px, 1022px, '../images/_sprites-full.png', 'title-bg-mb', );
$title-bg-name: 'title-bg';
$title-bg-x: 0px;
$title-bg-y: 152px;
$title-bg-offset-x: 0px;
$title-bg-offset-y: -152px;
$title-bg-width: 750px;
$title-bg-height: 152px;
$title-bg-total-width: 1243px;
$title-bg-total-height: 1022px;
$title-bg-image: '../images/_sprites-full.png';
$title-bg: (0px, 152px, 0px, -152px, 750px, 152px, 1243px, 1022px, '../images/_sprites-full.png', 'title-bg', );
$topup-mb-name: 'topup-mb';
$topup-mb-x: 376px;
$topup-mb-y: 484px;
$topup-mb-offset-x: -376px;
$topup-mb-offset-y: -484px;
$topup-mb-width: 338px;
$topup-mb-height: 90px;
$topup-mb-total-width: 1243px;
$topup-mb-total-height: 1022px;
$topup-mb-image: '../images/_sprites-full.png';
$topup-mb: (376px, 484px, -376px, -484px, 338px, 90px, 1243px, 1022px, '../images/_sprites-full.png', 'topup-mb', );
$topup-side-name: 'topup-side';
$topup-side-x: 1105px;
$topup-side-y: 0px;
$topup-side-offset-x: -1105px;
$topup-side-offset-y: 0px;
$topup-side-width: 138px;
$topup-side-height: 90px;
$topup-side-total-width: 1243px;
$topup-side-total-height: 1022px;
$topup-side-image: '../images/_sprites-full.png';
$topup-side: (1105px, 0px, -1105px, 0px, 138px, 90px, 1243px, 1022px, '../images/_sprites-full.png', 'topup-side', );
$topup-name: 'topup';
$topup-x: 0px;
$topup-y: 304px;
$topup-offset-x: 0px;
$topup-offset-y: -304px;
$topup-width: 376px;
$topup-height: 284px;
$topup-total-width: 1243px;
$topup-total-height: 1022px;
$topup-image: '../images/_sprites-full.png';
$topup: (0px, 304px, 0px, -304px, 376px, 284px, 1243px, 1022px, '../images/_sprites-full.png', 'topup', );
$video-name: 'video';
$video-x: 750px;
$video-y: 440px;
$video-offset-x: -750px;
$video-offset-y: -440px;
$video-width: 132px;
$video-height: 134px;
$video-total-width: 1243px;
$video-total-height: 1022px;
$video-image: '../images/_sprites-full.png';
$video: (750px, 440px, -750px, -440px, 132px, 134px, 1243px, 1022px, '../images/_sprites-full.png', 'video', );
$spritesheet-width: 1243px;
$spritesheet-height: 1022px;
$spritesheet-image: '../images/_sprites-full.png';
$spritesheet-sprites: ($all, $char-bar-bg, $char-left-hov, $char-left, $code-hov, $code, $decor-corner, $download-mb, $download-top, $dropdown, $events-page-active, $events-page, $facebook-top, $features-benefit-hov, $features-benefit, $features-character-hov, $features-character, $features-clan-hov, $features-clan, $features-pk-hov, $features-pk, $gallery-page-hov, $gallery-page, $gallery-tab-bg-hov, $gallery-tab-bg, $gallery-tab-line, $logo-pw, $logo-vng, $music-mute, $music, $nav-split, $page-next, $page-prev, $prereg-mb, $rank-next, $rank-prev, $rank-tab-bg-act, $rank-tab-bg-hov, $rank-tab-bg, $side-close, $side-open, $split-side, $star, $store-appstore-side-hov, $store-appstore-side, $store-appstore-hov, $store-appstore, $store-googleplay-side-hov, $store-googleplay-side, $store-googleplay-hov, $store-googleplay, $store-nox-hov, $store-nox, $store-pc-side-hov, $store-pc-side, $store-pc-hov, $store-pc, $title-bg-mb, $title-bg, $topup-mb, $topup-side, $topup, $video, );
$spritesheet: (1243px, 1022px, '../images/_sprites-full.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
