@import "../configs/mixins/clear_default";

.characters {
    position: relative;
    height: 1689px;
    background: transparent;
    pointer-events: none;
    margin-top: -230px;
    z-index: 3;

    @include grid-media($portrait) {
        height: calc(1200px + 212px);
        padding-top: 0px;
        margin-top: 0px;
    }

    &--norank {
        @include grid-media($portrait) {
            margin-top: -530px;
        }
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }

        .circle {
            display: block;
            width: 999px;
            height: 1002px;

            position: absolute;
            top: 390px;
            left: calc(50% - 640px);

            @include grid-media($portrait) {
                top: 150px;
                left: calc(50% - 140px);
            }

            animation: openportal 20s infinite;

            @keyframes openportal {
                0% {
                    filter: brightness(1) blur(0px);
                    transform: scale(1) rotate(0deg);
                }
                30% {
                    filter: brightness(1) blur(3px);
                    transform: scale(.95) rotate(-15deg);
                }
                80% {
                    filter: brightness(1.2) blur(0px);
                    transform: scale(1.05) rotate(180deg);
                }
                100% {
                    filter: brightness(1) blur(0px);
                    transform: scale(1) rotate(0deg);
                }

            }
        }

        .land {
            display: block;
            width: 1288px;
            height: 431px;

            position: absolute;
            top: 1180px;
            left: calc(50% - 820px);

            animation: openland 30s infinite;

            @keyframes openland {
                0% {
                    filter: brightness(1);
                    transform: scale(1) ;
                }
                30% {
                    filter: brightness(1);
                    transform: scale(.95) ;
                }
                80% {
                    filter: brightness(1.3);
                    transform: scale(1.1) ;
                }
                100% {
                    filter: brightness(1);
                    transform: scale(1);
                }

            }
        }
    }

    &__content {
        position: relative;
        height: 100%;

        // pointer-events: all;

        &:before {
            content: "";
            display: block;
            height: 255px;
            width: 100%;

            @include grid-media($portrait) {
                display: none;
            }
        }

        .swiper-container {
            pointer-events: all;
        }

        .swiper-button-prev-listChars, .swiper-button-next-listChars {
            @include clear_swiper_navigation;
            pointer-events: all; 

            &:after {
                display: none;
            }

            @include grid-media($portrait) {
                display: none;
            }
        }

        .swiper-button-prev-listChars {
            @include sprite($char-left);
            left: 250px;
        }

        .swiper-button-next-listChars { 
            @include sprite($char-left);
            right: 250px;
            transform: scaleX(-1);
        }

        .swiper-pagination-listChars {
            @include clear_swiper_pagination; 
            position: static;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: calc(100% - 26px);

            .swiper-pagination-bullet {
                @include clear_swiper_pagination_bullet;

                display: block;
                width: 100%;
                height: 40px;

                @include grid-media($portrait) {
                    width: 90px;
                    height: 100%;
                    margin: 0 18px;
                }
                

                &:not(:last-child) {
                    margin-bottom: 1px;
                }


                .thumbnail {
                    display: none;

                    @include grid-media($portrait) {
                        display: block;
                    }

                    &__normal {
                        display: block;
                    }
                    &__hover {
                        display: none;
                    }
                }

                .label {
                    position: relative;
                    text-transform: capitalize;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    color: #3355ab;
                    background: #ffdd8c;
                    font-size: 18px;

                    @include grid-media($portrait) {
                        display: none;
                    }
                    
                    &:before, &:after {
                        content: "";
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: #cfae47;

                        transform: rotate(45deg);
                        position: absolute;
                        top: 17px;
                    }

                    &:before {
                        left: 6px;
                    }

                    &:after {
                        right: 6px;
                    }

                }

                &-active {
                    .thumbnail {
                        &__normal {
                            display: none;
                        }
                        &__hover {
                            display: block;
                        }
                    }
                }

                &:hover, &-active, &-active:hover {
                    .label {
                        background: #fbeda1;
                    }
                }
            }
        }

        .swiper-slide[data-label="barbarian"] {
            .thumbnail {
                // img {
                //    width: 599px;
                //    height: 1041px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                       width: 708px;
                       height: 857px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="vulpine"] {
            .thumbnail {
                img {
                //    width: 621px;
                //    height: 1001px; 
                }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                       width: 715px;
                       height: 975px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="cleric"] {
            .thumbnail {
                // left: calc(50% - 480px);
                // img {
                //    width: 730px;
                //    height: 1034px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                       width: 632px;
                       height: 937px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="archer"] {
            .thumbnail {
                // bottom: 150px;
                // left: calc(50% - 680px);
                // img {
                //    width: 1190px;
                //    height: 1027px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;
                    img {
                       width: 768px;
                       height: 991px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="blademaster"] {
            .thumbnail {
                // bottom: 50px;
                // left: calc(50% - 520px);
                // img {
                //    width: 892px;
                //    height: 1191px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                       width: 644px;
                       height: 1056px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="wizard"] {
            .thumbnail {
                // img {
                //    width: 786px;
                //    height: 1007px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                       width: 728px;
                       height: 975px; 
                    }
                }
            }
        }
        .swiper-slide[data-label="soul"] {
            .thumbnail {
                // img {
                //    width: 786px;
                //    height: 1007px; 
                // }

                @include grid-media($portrait) {
                    bottom: -4px;
                    left: auto;
                    right: 0;

                    img {
                        width: 632px;
                        height: 937px; 
                    }
                }
            }
        }

    }


}
