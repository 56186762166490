.general {
    position: absolute;
    pointer-events: none;
    width: 1200px;
    left: 50%;
    transform: translateX(-50%) translateY(-600px);
    z-index: 3;

    @include grid-media($portrait) {
        width: 100%;
        position: relative;
        transform: translateX(-50%) translateY(-230px);
    }

    &--norank {
        transform: translateX(-50%) translateY(-900px);

        @include grid-media($portrait) {
            transform: translateX(-50%) translateY(-530px);
        }
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        pointer-events: all;

        @include grid-media($portrait) {
            flex-wrap: wrap;
        }


        .appinfo {
            width: 382px;
            pointer-events: all;

            @include grid-media($portrait) {
                width: 100%;
                height: 230px;
                display: flex;
                justify-content: center;
            }
            &__cta {
                margin-bottom: 10px;
                .topup {
                    display: block;
                    @include sprite($topup);
        
                    &:hover {
                        filter: brightness(120%); 
                    }
                }
                // .topup {
                //     display: block;
                //     @include sprite($prereg);
        
                //     &:hover {
                //         filter: brightness(120%);
                //     }
                // }

                @include grid-media($portrait) {
                    .topup {
                        display: block;
                        @include sprite($topup-mb);
                        margin: 8px auto;
            
                        &:hover {
                            filter: brightness(120%);
                        }
                    }
                    // .topup {
                    //     display: block;
                    //     @include sprite($prereg-mb);
                    //     margin: 8px auto;
            
                    //     &:hover {
                    //         filter: brightness(120%);
                    //     }
                    // }
                    .download {
                        display: block;
                        @include sprite($download-mb);
                        margin: 8px auto;
            
                        &:hover {
                            filter: brightness(120%);
                        }
                    }
                }
            }
            &__download {
                width: 382px;
                height: 170px;
                margin-bottom: 20px;

                position: relative;
                .ios {
                    display: block;
                    @include sprite($store_appstore);

                    position: absolute;
                    left: 0;
                    top: 0;
        
                    &:hover {
                        @include sprite($store_appstore-hov);
                    }
                }
                .android {
                    display: block;
                    @include sprite($store_googleplay);

                    position: absolute;
                    right: 0;
                    top: 0;
        
                    &:hover {
                        @include sprite($store_googleplay-hov);
                    }
                }
                .apk {
                    display: block;
                    @include sprite($store_pc);

                    position: absolute;
                    left: 0;
                    bottom: 0;
        
                    &:hover {
                        @include sprite($store_pc-hov);
                    }
                }
                .nox {
                    display: block;
                    @include sprite($store_nox);

                    position: absolute;
                    right: 0;
                    bottom: 0;
        
                    &:hover {
                        @include sprite($store_nox-hov);
                    }
                }

                @include grid-media($portrait) {
                    display: none;
                }
            }
            &__features {
                width: 382px;
                height: 170px;

                position: relative;
                margin-bottom: 20px;

                .char {
                    display: block;
                    @include sprite($features-character);
        
                    position: absolute;
                    left: 0;
                    top: 0;

                    &:hover {
                        @include sprite($features-character-hov);
                    }
                }
                .benefit {
                    display: block;
                    @include sprite($features-benefit);
        
                    position: absolute;
                    right: 0;
                    top: 0;

                    &:hover {
                        @include sprite($features-benefit-hov);
                    }
                }
                .clan {
                    display: block;
                    @include sprite($features-clan);
        
                    position: absolute;
                    left: 0;
                    bottom: 0;

                    &:hover {
                        @include sprite($features-clan-hov);
                    }
                }
                .pk {
                    display: block;
                    @include sprite($features-pk);

                    position: absolute;
                    right: 0;
                    bottom: 0;
        
                    &:hover {
                        @include sprite($features-pk-hov);
                    }
                }
            }

            &__code {
                width: 380px;
                height: 120px;

                position: relative;

                .code {
                    display: block;
                    @include sprite($code);
        
                    position: absolute;
                    left: 0;
                    top: 0;

                    &:hover {
                        @include sprite($code-hov);
                    }
                }
            }
        }

        .information {
            margin-top: 154px;

            @include grid-media($portrait) {
                background: #daedfe;
                width: 100%;
                margin-top: initial;
            }


            .border_news {
                margin-bottom: 20px;
                @include grid-media($portrait) {
                    margin: 0 auto;
                }
            }
            .events {
                display: block;
                width: 780px;
                height: 310px;
                max-width: 100%;
                background: url(../../dist/assets/images/bg/list-events.jpg);

                @include grid-media($portrait) {
                    width: 690px;
                    height: 310px;
                    margin: 0 auto;
                }
            }
        }   

    }
}