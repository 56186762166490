.rank {
    position: relative;
    height: 1643px;
    background: transparent;
    pointer-events: none;
    margin-top: -170px;

    @include grid-media($portrait) {
        height: 1200px;
        padding-top: 0px;
        margin-top: -230px;
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.desktop {
                @include grid-media($portrait) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include grid-media($portrait) {
                    display: block;
                }
            }
        }
    }

    &__content {
        position: relative;
        height: 100%;

        pointer-events: all;

        &:before {
            content: "";
            display: block;
            height: 365px;
            width: 100%;

            @include grid-media($portrait) {
                display: none;
            }
        }

        .rank__server {
            display: flex;
            width: 100%;
            justify-content: center;

            .form-control {
                margin: 0 30px;

                select + .select2 {
                    .select2-selection {
                        border-radius: 50px;
                        width: 240px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border: 2px solid #b8e2fd;

                        &__rendered {
                            color: #1e3f51;
                            font-size: 20px;
                            line-height: 26px;
                            padding: 0 20px;
                        }
                        &__arrow {
                            @include sprite($dropdown);
                            top: calc(50% - 20px / 2);
                        }
                    }

                    &.select2-container {
                        &--open {
                            .selection {
                                .select2-selection {
                                    &__arrow {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                        &--focus {
                            .selection {
                                .select2-selection {
                                    border: 2px solid #2596E8;
                                    box-shadow: 0 0 0 1px #2596E8;
                                }
                            }
                        }
                    }
                }

            }

        }

        .rank__single {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            ul {
                width: 100%;
            }
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                font-size: 20px;
                color: #1e3f51;

                &:nth-child(1) {
                    width: calc(50% / 3 * 1);
                }
                &:nth-child(2) {
                    width: calc(50% / 3 * 2);
                }
                &:nth-child(3) {
                    width: calc(50% / 3);
                }
                &:nth-child(4) {
                    width: calc(50% / 3);
                }
                &:nth-child(5) {
                    width: calc(50% / 3);
                }
            }

            &:not(:last-child) {
                &:after {
                    content: "";
                    display: block;

                    width: 100%;
                    height: 1px;
                    background: rgb(199,220,249);
                    background: radial-gradient(circle, rgba(199,220,249,1) 50%, rgba(199,220,249,0) 100%);
                }
            }

            &--top1, &--top2, &--top3 {
                font-weight: 600; 


                li:first-child {
                    .icon {
                        margin-left: 10px;
                    }
                }
            }

            &--top1 {
                li:first-child {
                    color: #fc2126;

                    .icon > svg > path {
                        fill: #fc2126;
                    }
                }
            }
            &--top2 {
                li:first-child {
                    color: #ef8508;

                    .icon > svg > path {
                        fill: #ef8508;
                    }
                }
            }
            &--top3 {
                li:first-child {
                    color: #ef8508;

                    .icon > svg > path {
                        fill: #ef8508;
                    }
                }
            }
        }

        .rank__label {
            .rank__single { 
                li {
                    font-size: 28px; 
                    color: #3355ab;
                }
            
            }
        }

        .rank__page {
            display: flex;
            width: 100%;
            justify-content: center;

            ul {
                display: flex;
                justify-self: center;
                li {
                    margin: 0 2px;

                    &, .page, .text {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        height: 42px;
                    }

                    .prev {
                        font-size: 0;
                        @include sprite($page-prev);
                    }
                    .next {
                        font-size: 0;
                        @include sprite($page-next);
                    }
                    .page {
                        width: 42px;
                        height: 42px;
                        border-radius: 20px;
                        color: #3355ab;
                        font-weight: bold;
                        text-decoration: none;



                        &.active {
                            background: #f1db88;
                        }

                        &:hover {
                            position: relative;
                            z-index: 2;
                            &:before {
                                content: "";
                                display: block;
                                width: 80%;
                                height: 80%;
                                position: absolute;
                                z-index: -1;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                border-radius: 20px;
                                border-width: 1px;
                                border-style: solid;
                                border-color: #f1db88;
                                background: rgba(#f1db88, .2);
                            }
                        }
                    }

                    .text {
                        height: 42px;
                        width: 42px;
                    }
                }
            }
        }

    }

    &__data {
        display: none; 

        &.active {
            display: block;
        }
    }
}

.select2-container {
    .select2-dropdown {
        border: 2px solid #b8e2fd !important;
        border-radius: 12px !important;
        overflow: hidden;
        
        .select2-results {
            &__options {

            }
            &__option {

            }
        }
    }
}